import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/gatsby-theme-teranova/src/components/layout.js";
import Intro from 'gatsby-theme-teranova/src/components/intro';
import About from 'gatsby-theme-teranova/src/components/about';
import Services from 'gatsby-theme-teranova/src/components/services';
import Team from 'gatsby-theme-teranova/src/components/team';
import Contact from 'gatsby-theme-teranova/src/components/contact';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Intro mdxType="Intro" />
    <About mdxType="About" />
    <Services mdxType="Services" />
    <Team mdxType="Team" />
    <Contact mdxType="Contact" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      